<template>
  <div>
    <!-- Floating action button -->
    <v-btn
      ref="chatButton"
      class="chat-fab"
      icon="mdi-robot"
      color="primary"
      size="large"
      @click="toggleChat"
      :elevation="2"
    />

    <!-- Chat Dialog -->
    <v-menu
      v-model="showChat"
      :close-on-content-click="false"
      location="end"
      :activator="'parent'"
      offset="20"
      transition="slide-y-transition"
    >
      <v-card class="chat-card">
        <v-card-title class="d-flex justify-space-between align-center pa-4">
          <span>AI Assistant</span>
          <v-btn icon="mdi-close" size="small" @click="showChat = false" />
        </v-card-title>

        <v-card-text class="pa-4">
          <div class="chat-container" ref="chatContainer">
            <div v-for="(message, index) in messages" :key="index" class="message-wrapper">
              <div :class="['message', message.role === 'assistant' ? 'assistant' : 'user']">
                {{ message.content }}
              </div>
            </div>
            <div v-if="isTyping" class="typing-indicator">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="chat-input pa-4">
          <v-text-field
            v-model="newMessage"
            placeholder="Type a message..."
            @keyup.enter="sendMessage"
            hide-details
            density="compact"
            variant="outlined"
            class="mr-2"
          />
          <v-btn
            icon="mdi-send"
            color="primary"
            :disabled="!newMessage.trim()"
            @click="sendMessage"
          />
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { ref, watch, nextTick, defineExpose } from 'vue';
import { useToast } from 'vue-toastification';

const toast = useToast();
const showChat = ref(false);
const messages = ref([]);
const newMessage = ref('');
const isTyping = ref(false);
const chatContainer = ref(null);

const toggleChat = () => {
  showChat.value = !showChat.value;
};

const scrollToBottom = () => {
  nextTick(() => {
    if (chatContainer.value) {
      chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
    }
  });
};

const sendMessage = async () => {
  if (!newMessage.value.trim() || isTyping.value) return;

  const messageText = newMessage.value;
  messages.value.push({
    role: 'user',
    content: messageText
  });

  newMessage.value = '';
  isTyping.value = true;
  scrollToBottom();

  try {
    // TODO: Implement API call to AI service
    // For now, just echo back
    setTimeout(() => {
      messages.value.push({
        role: 'assistant',
        content: `I received: ${messageText}`
      });
      isTyping.value = false;
      scrollToBottom();
    }, 1000);
  } catch (error) {
    toast.error('Failed to send message');
    isTyping.value = false;
  }
};

// Method to be called from other components
const receiveExternalMessage = (message) => {
  showChat.value = true;
  messages.value.push({
    role: 'system',
    content: message
  });
  scrollToBottom();
};

// Expose methods for external components
defineExpose({
  receiveExternalMessage
});

watch(showChat, (newValue) => {
  if (newValue) {
    scrollToBottom();
  }
});
</script>

<style scoped>
.chat-fab {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.chat-card {
  width: 600px;
  max-height: 500px;
  margin-bottom: 60px;
}

.chat-container {
  height: 250px;
  overflow-y: auto;
  padding: 8px;
}

.message-wrapper {
  margin-bottom: 8px;
}

.message {
  max-width: 90%;
  padding: 8px 12px;
  border-radius: 12px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.user {
  background-color: #e3f2fd;
  margin-left: auto;
  border-bottom-right-radius: 4px;
}

.assistant {
  background-color: #f5f5f5;
  margin-right: auto;
  border-bottom-left-radius: 4px;
}

.chat-input {
  display: flex;
  gap: 8px;
  padding: 8px;
  background-color: white;
}

.typing-indicator {
  display: flex;
  gap: 4px;
  padding: 8px 12px;
  background: #f5f5f5;
  border-radius: 12px;
  width: fit-content;
}

.typing-indicator span {
  width: 6px;
  height: 6px;
  background: #999;
  border-radius: 50%;
  animation: bounce 1s infinite;
}

.typing-indicator span:nth-child(2) { animation-delay: 0.2s; }
.typing-indicator span:nth-child(3) { animation-delay: 0.4s; }

@keyframes bounce {
  0%, 60%, 100% { transform: translateY(0); }
  30% { transform: translateY(-4px); }
}
</style> 