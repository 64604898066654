<template>
  <v-dialog v-model="showDialog" max-width="400px">
    <template v-slot:activator="{ props }">
      <v-list-item-title 
        v-bind="props"
        style="cursor: pointer"
        class="d-flex align-center"
      >
        <v-icon start class="mr-2">mdi-lock</v-icon>
        Set Verification PIN
      </v-list-item-title>
    </template>

    <v-card>
      <v-card-title class="text-h6">
        Set Verification PIN
      </v-card-title>

      <v-card-text>
        <v-form ref="pinForm" v-model="pinFormValid">
          <v-text-field
            v-model="pin"
            label="Enter 4-digit PIN"
            type="password"
            maxlength="4"
            :rules="[v => !!v || 'PIN is required', v => /^\d{4}$/.test(v) || 'PIN must be exactly 4 digits']"
            @input="pin = pin.replace(/[^0-9]/g, '').slice(0, 4)"
          ></v-text-field>
          <v-text-field
            v-model="confirmPin"
            label="Confirm PIN"
            type="password"
            maxlength="4"
            :rules="[
              v => !!v || 'PIN is required', 
              v => /^\d{4}$/.test(v) || 'PIN must be exactly 4 digits',
              v => v === pin || 'PINs do not match'
            ]"
            @input="confirmPin = confirmPin.replace(/[^0-9]/g, '').slice(0, 4)"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="savePin"
          :loading="saving"
          :disabled="!pinFormValid || saving"
        >
          Save PIN
        </v-btn>
        <v-btn
          color="grey"
          variant="text"
          @click="showDialog = false"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { useToast } from "vue-toastification"
import { updateUserPin } from '@/api/apiClient'

const toast = useToast()
const showDialog = ref(false)
const pin = ref('')
const confirmPin = ref('')
const pinFormValid = ref(false)
const saving = ref(false)
const pinForm = ref(null)

const savePin = async () => {
  if (!pinFormValid.value) return
  
  saving.value = true
  try {
    await updateUserPin(pin.value)
    toast.success('PIN updated successfully')
    showDialog.value = false
    pin.value = ''
    confirmPin.value = ''
  } catch (error) {
    console.error('Error updating PIN:', error)
    toast.error('Failed to update PIN')
  } finally {
    saving.value = false
  }
}
</script> 