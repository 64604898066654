<template>
  <div id="app">    
    <router-view></router-view>
    <ChatBot ref="chatBot" />
  </div>
</template>

<script>
import { computed, ref, onMounted, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ChatBot from './components/ChatBot.vue'

export default {
  name: 'App',
  components: {
    ChatBot
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const chatBot = ref(null)

    onMounted(() => {
      // Make chatBot available globally
      const app = getCurrentInstance();
      if (app) {
        app.appContext.config.globalProperties.$chatBot = chatBot.value;
      }
    });

    const isLoggedIn = computed(() => store.getters.isLoggedIn)

    const logout = () => {
      store.dispatch('logout')
      router.push('/login')
    }

    // Method to send messages to chatbot from any component
    const sendToChatBot = (message) => {
      if (chatBot.value) {
        chatBot.value.receiveExternalMessage(message);
      }
    }

    return {
      isLoggedIn,
      logout,
      chatBot,
      sendToChatBot
    }
  }
}
</script>