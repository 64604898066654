import axios from 'axios';
import router from '@/router'; // Import your Vue Router instance
import store from '@/store';
import { useToast } from "vue-toastification"; // Import the toast function

const toast = useToast();

const getBaseUrl = () => {
  // Check if window.ENV exists (set by our runtime config)
  if (window.ENV && window.ENV.VUE_APP_API_BASE_URL) {
    return window.ENV.VUE_APP_API_BASE_URL;
  }
  // Fallback to the build-time environment variable
  return process.env.VUE_APP_API_BASE_URL || 'http://localhost:3000';
};

const apiClient = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401 && error.config.url !== '/api/auth/login') {
      store.dispatch('logout');
      toast.error("Your session has expired. Please log in again.");
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

console.log('API Base URL:', getBaseUrl());

// Function to lookup products
export const lookupProduct = async (query, page = 1, itemsPerPage = 5) => {
  const response = await apiClient.get('api/products', {
    params: {
      query: query,
      page: page,
      per_page: itemsPerPage
    }
  });
  return response;
};

// Function to save batch code information
export const saveBatchCode = (batchCodeData) => {
  if (batchCodeData._id) {
    // If the batch code has an ID, it's an update
    return apiClient.put(`/api/batch-codes/${batchCodeData._id}`, batchCodeData);
  } else {
    // If there's no ID, it's a new batch code
    return apiClient.post('/api/batch-codes', batchCodeData);
  }
};

// Function to search batch codes
export const searchBatchCodes = (query) => {
  return apiClient.get(`/api/batch-codes`, { params: { query } });
};

// Function to get a specific batch code by ID
export const getBatchCodesByProductId = (id) => {
  console.log('getBatchCodesByProductId',id);
  return apiClient.get(`/api/batch-codes/${id}`);
};

// Function to get all batch codes with pagination and search
export const getBatchCodes = async (params) => {
  const response = await apiClient.get('/api/batch-codes', { params });
  return response.data;
};

// Function to delete a batch code
export const deleteBatchCode = (id) => {
  return apiClient.delete(`/api/batch-codes/${id}`);
};

// Upload files to the server
export const uploadFiles = (formData) => {
  return apiClient.post('/api/invoices/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// New function to get processing updates
export const getProcessingUpdates = (onUpdate, onDone) => {
  const eventSource = new EventSource(`${getBaseUrl()}/api/invoices/processing-updates`);

  eventSource.onmessage = (event) => {
    try {
      if (event.data && event.data !== "undefined") {
        const data = JSON.parse(event.data);
        if (data.done) {
          eventSource.close();
          onDone();
        } else {
          onUpdate(data);
        }
      } else {
        console.warn('Received empty or undefined event data');
      }
    } catch (error) {
      console.error('Error parsing event data:', error);
    }
  };

  eventSource.onerror = (error) => {
    console.error('EventSource failed:', error);
    eventSource.close();
  };

  return () => {
    eventSource.close();
  };
};

// Function to get invoices with pagination, sorting, and filtering
export const getInvoices = (page, itemsPerPage, sortBy, sortDesc, query) => {
  const params = {
    page,
    itemsPerPage,
    sortBy,
    sortDesc,
    query,
  };
  return apiClient.get('/api/invoices', { params });
};

// Function to get a specific invoice by ID
export const getInvoiceById = (invoiceId) => {
  return apiClient.get(`/api/invoices/${invoiceId}`);
};

// Function to create a new invoice
export const createInvoice = (invoiceData) => {
  return apiClient.post('/api/invoices', invoiceData);
};

// Function to update an existing invoice
export const updateInvoice = (invoiceId, invoiceData) => {
  // Ensure that productLines are properly formatted
  const formattedInvoiceData = {
    ...invoiceData,
    productLines: invoiceData.productLines.map(line => ({
      ...line,
      price: line.newPrice || line.price // Use newPrice if it exists, otherwise use the original price
    }))
  };

  return apiClient.put(`/api/invoices/${invoiceId}`, formattedInvoiceData);
};

// Function to delete an invoice
export const deleteInvoice = (invoiceId) => {
  return apiClient.delete(`/api/invoices/${invoiceId}`);
};

// Add this function to fetch entities
export const getEntities = () => {
  return apiClient.get('/api/invoices/entities');
};

// Function to link a product to an invoice line
export const linkProductToInvoiceLine = (invoiceId, productName, productId, variantId) => {
  return apiClient.post(`/api/invoices/${invoiceId}/lines/${productName}/link-product`, { productId, variantId });
};

// New function to approve an invoice
export async function approveInvoice(invoiceId, approvalData) {
  try {
    const response = await apiClient.post(`/api/invoices/${invoiceId}/approve`, approvalData);
    return response.data;
  } catch (error) {
    console.error('Error approving invoice:', error);
    throw error;
  }
}

// Function to get BOMs with search functionality
export const getBOMs = async ({ search = '', page = 1, limit = 10 }) => {
  const response = await apiClient.get('/api/bom', { 
    params: { search, page, limit }
  });
  return response.data;
};

export const getBOMById = async (id) => {
  return await apiClient.get(`/api/bom/${id}`);
};

// Function to create a new BOM
export const createBOM = (bomData) => {
  console.log('Creating BOM:', bomData);
  return apiClient.post('/api/bom', bomData);
};

// Function to update an existing BOM
export const updateBOM = (bomId, bomData) => {
  return apiClient.put(`/api/bom/${bomId}`, bomData);
};

// Function to delete a BOM
export const deleteBOM = (bomId) => {
  return apiClient.delete(`/api/bom/${bomId}`);
};

// Add these functions to the existing apiClient.js file

export const getManufacturingOrders = async ({ search = '', page = 1, itemsPerPage = 10 }) => {
  return await apiClient.get('/api/manufacturing-orders', {
    params: { search, page, itemsPerPage }
  });
};

export const createManufacturingOrder = async (orderData) => {
  return await apiClient.post('/api/manufacturing-orders', orderData);
};

export const updateManufacturingOrder = async (orderId, orderData) => {
  return await apiClient.put(`/api/manufacturing-orders/${orderId}`, orderData);
};

export async function updateManufacturingOrderStatus(orderId, newStatus) {
  try {
    const response = await apiClient.patch(`/api/manufacturing-orders/${orderId}/status`, { status: newStatus });
    return response.data;
  } catch (error) {
    console.error('Error updating manufacturing order status:', error);
    throw error;
  }
}

export const deleteManufacturingOrder = async (orderId) => {
  return await apiClient.delete(`/api/manufacturing-orders/${orderId}`);
};

export const getWarehouses = async () => {
  return await apiClient.get('/api/warehouse');
};

export const getPurchaseOrders = async (params) => {
  try {
    const response = await apiClient.get('/api/purchase-orders', { params });
    return response.data || { purchaseOrders: [], total: 0 };
  } catch (error) {
    console.error('Error fetching purchase orders:', error);
    throw error;
  }
};

export const createPurchaseOrderInvoice = async (purchaseOrderId) => {
  return await apiClient.post(`/api/purchase-orders/${purchaseOrderId}/create-invoice`);
};

export const downloadPurchaseOrderPDF = async (purchaseOrderId, purchaseOrderNumber) => {
  try {
    const response = await apiClient.get(`/api/purchase-orders/${purchaseOrderId}/pdf`, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `purchase_order_${purchaseOrderNumber}.pdf`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Error downloading purchase order PDF:', error);
    throw error;
  }
};

//user management
export const getUsers = async ({ search = '', page = 1, limit = 10 }) => {
  console.log('Getting users with search:', search);
  return await apiClient.get('/api/auth/users', { params: { search, page, limit } });
};

export const createUser = async (userData) => {
  return await apiClient.post('/api/auth/register', userData);
};

export const updateUserStatus = async (userId, status) => {
  return await apiClient.patch(`/api/auth/users/${userId}/status`, { status });
};

export const resetUserPassword = async (userId) => {
  return await apiClient.post(`/api/auth/admin-reset-password/${userId}`);
};

// User-initiated password reset
export const requestPasswordReset = async (email) => {
  return await apiClient.post('/api/auth/reset-password', { email });
};

export const updateUser = async (userId, userData) => {
  return await apiClient.put(`/api/auth/users/${userId}`, userData);
};

// SKU codes
export const generateSKUCode = async (skuData) => {
  return await apiClient.post('/api/products/sku-codes', skuData);
};

// Product management
export const getMissingSkuProducts = async (entityId, params) => {
  return await apiClient.get(`/api/products/missing-skus/${entityId}`, { params });
};

export const updateProductSkus = async (entityId, products) => {
  return await apiClient.post(`/api/products/update-skus/${entityId}`, { products });
};

export async function getSuppliers() {
  return await apiClient.get('/api/products/suppliers');
}

export async function getCategories(entityId) {
  return await apiClient.post('/api/products/categories', { entityId });
}

// Stock take

export const getStockTakes = async ({ search = '', page = 1, itemsPerPage = 10 }) => {
  try {
    const response = await apiClient.get('/api/stock-takes', {
      params: { search, page, itemsPerPage }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching stock takes:', error);
    throw error;
  }
};

export const getStockTakeById = async (id) => {
  try {
    const response = await apiClient.get(`/api/stock-takes/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching stock take:', error);
    throw error;
  }
};

export const createStockTake = async (stockTakeData) => {
  try {
    const response = await apiClient.post('/api/stock-takes', stockTakeData);
    return response.data;
  } catch (error) {
    console.error('Error creating stock take:', error);
    throw error;
  }
};

export const getLocations = async (entityId) => {
  return await apiClient.post('/api/stock-takes/locations', { entityId });
};

export const getBrands = async (entityId) => {
  return await apiClient.post('/api/products/brands', { entityId });
};

export const updateStockTake = async (id, stockTakeData) => {
  try {
    const response = await apiClient.put(`/api/stock-takes/${id}`, stockTakeData);
    return response.data;
  } catch (error) {
    console.error('Error updating stock take:', error);
    throw error;
  }
};

export const deleteStockTake = async (id) => {
  try {
    const response = await apiClient.delete(`/api/stock-takes/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting stock take:', error);
    throw error;
  }
};

export const getStockTakeSummary = async (stockTakeId) => {
  try {
    const response = await apiClient.get(`/api/stock-takes/${stockTakeId}/summary`);
    return response.data;
  } catch (error) {
    console.error('Error fetching stock take summary:', error);
    throw error;
  }
};
export const updateStockTakeStatus = async (stockTakeId, newStatus) => {
  const response = await apiClient.put(`/api/stock-takes/${stockTakeId}/status`, { status: newStatus });
  return response.data;
};

// Function to download stock sheet PDF
export const downloadStockSheetPdf = async (stockTakeId) => {
  return apiClient.get(`/api/stock-takes/${stockTakeId}/generate-stock-sheet`, {
    responseType: 'blob',
  });
};

// Add this new function
export const updateStockTakeItemCount = async (stockTakeId, itemUpdate) => {
  try {
    const response = await apiClient.put(`/api/stock-takes/${stockTakeId}/item-count`, itemUpdate);
    console.log('updateStockTakeItemCount response', JSON.stringify(response,null,2));
    return response.data;
  } catch (error) {
    console.error('Error updating stock take item count:', error);
    throw error;
  }
};

export const getInventorySnapshots = (params) => {
  return apiClient.get('/api/inventory-snapshots', { params });
};

export const getInventorySnapshotDetails = (id) => {
  return apiClient.get(`/api/inventory-snapshots/${id}/details`);
};

export const createInventorySnapshot = (data) => {
  return apiClient.post('/api/inventory-snapshots', data);
};

export const deleteInventorySnapshot = (id) => {
  return apiClient.delete(`/api/inventory-snapshots/${id}`);
};

export const downloadInventorySnapshot = async (id) => {
  try {
    const response = await apiClient.get(`/api/inventory-snapshots/${id}/download`, {
      responseType: 'blob'
    });
    
    // Get the filename from the Content-Disposition header
    console.log('response headers:', JSON.stringify(response.headers));
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'inventory_snapshot.xlsx';
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
      if (filenameMatch && filenameMatch.length === 2) {
        filename = filenameMatch[1].replace(/['"]/g, ''); // Remove any quotes
      }
    }

    // If we couldn't get the filename from the header, generate one based on the current date
    if (filename === 'inventory_snapshot.xlsx') {
      const currentDate = new Date().toISOString().split('T')[0];
      filename = `inventory_snapshot_${currentDate}.xlsx`;
    }

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Error downloading inventory snapshot:', error);
    throw error;
  }
};

//logistics reconciliation
export const getInvoicesForReconciliation = async (params) => {
  return await apiClient.get('/api/logistics/reconciliation', { params });
};

export const generateInvoiceCrossCharges = async (invoiceId, account, channel, invoiceAccount, invoiceChannel) => {
  try {
    const response = await apiClient.post(`/api/logistics/reconciliation/${invoiceId}/generate-cross-charges`, {
      account,
      channel,
      invoiceAccount,
      invoiceChannel
    });
    return response;  // The response should include the updated invoice data
  } catch (error) {
    // If the server sends a specific error message, pass it through
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    // Otherwise, throw the original error
    throw error;
  }
};

//upload invoices
export const uploadInvoice = async (formData) => {
  return await apiClient.post('/api/logistics/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Add this new function to your apiClient.js
export const deleteLogisticsInvoice = async (invoiceId) => {
  return await apiClient.delete(`/api/logistics/invoices/${invoiceId}`);
};

// Add this new function to get shipping providers
export const getShippingProviders = async () => {
  return await apiClient.get('/api/logistics/providers');
};

//wholesale orders
export const getWholesaleOrders = async ({ search = '', page = 1, limit = 10 }) => {
  try {
    const response = await apiClient.get('/api/wholesale-orders', {
      params: { search, page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching wholesale orders:', error);
    throw error;
  }
};

export const createWholesaleOrderInvoice = async (orderId, data) => {
  try {
    const response = await apiClient.post(`/api/wholesale-orders/${orderId}/create-invoice`, data);
    return response;
  } catch (error) {
    console.error('Error creating wholesale order invoice:', error);
    throw error;
  }
};

export const getPractitioners = () => {
  return apiClient.get('/api/practitioners');
};

export const createPractitioner = (practitionerData) => {
  return apiClient.post('/api/practitioners', practitionerData);
};

export const updatePractitioner = (id, practitionerData) => {
  return apiClient.put(`/api/practitioners/${id}`, practitionerData);
};

export const getPractitionerCommissions = (practitionerId) => {
  return apiClient.get(`/api/practitioners/${practitionerId}/commission-payments`);
};

export const getCommissionPaymentDetails = (practitionerId, paymentId) => {
  return apiClient.get(`/api/practitioners/${practitionerId}/commission-payments/${paymentId}`);
};

export const updatePractitionerPaymentStatus = async (practitionerId, paymentId, status) => {
  return await apiClient.put(`/api/practitioners/${practitionerId}/commission-payments/${paymentId}/status`, {
    status
  });
};

export const downloadSummaryReport = async (stockTakeId) => {
  try {
    const response = await apiClient.get(`/api/stock-takes/${stockTakeId}/summary-report`, {
      responseType: 'blob'
    });
    
    // Create a download link
    const blob = new Blob([response.data], { 
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `stock_take_summary_${stockTakeId}.xlsx`;
    
    // Trigger download
    document.body.appendChild(link);
    link.click();
    
    // Cleanup
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading summary report:', error);
    throw error;
  }
};

export const getStockMovementReports = async ({ page = 1, itemsPerPage = 10, search = '' }) => {
  const response = await apiClient.get('/api/stock-movement/reports', {
    params: {
      page: {
        page,
        itemsPerPage,
        search
      }
    }
  });
  return response.data;
};

export const createStockMovementReport = async (formData) => {
  return await apiClient.post('/api/stock-movement/reports', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const downloadStockMovementReport = async (reportId) => {
  try {
    const response = await apiClient.get(`/api/stock-movement/reports/${reportId}/download`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    });
    
    // Check if we got an error response
    if (response.data.type && response.data.type.includes('application/json')) {
      // Convert blob to text to read error message
      const text = await response.data.text();
      const error = JSON.parse(text);
      throw new Error(error.message || 'Download failed');
    }
    
    return response.data;
  } catch (error) {
    console.error('Error downloading stock movement report:', error);
    throw error;
  }
};

export const getManufacturingOrderById = async (id) => {
  return await apiClient.get(`/api/manufacturing-orders/${id}`);
};

// Add these new functions for AI verification
export const startAIVerification = async (stockTakeId) => {
  try {
    const response = await apiClient.post(`/api/stock-takes/${stockTakeId}/verify`);
    return response.data;
  } catch (error) {
    console.error('Error starting AI verification:', error);
    throw error;
  }
};

export const sendMessageToAI = async (stockTakeId, message) => {
  try {
    const response = await apiClient.post(`/api/stock-takes/${stockTakeId}/ai-message`, { message });
    return response.data;
  } catch (error) {
    console.error('Error sending message to AI:', error);
    throw error;
  }
};

// Helper function to get auth token
const getAuthToken = () => {
  return localStorage.getItem('token');
};

export const getVerificationStreamUrl = (stockTakeId) => {
  const token = getAuthToken();
  return `${getBaseUrl()}/api/stock-takes/${stockTakeId}/verify?token=${token}`;
};

export const getAIMessageStreamUrl = (stockTakeId, message) => {
  const token = getAuthToken();
  return `${getBaseUrl()}/api/stock-takes/${stockTakeId}/ai-message?message=${encodeURIComponent(message)}&token=${token}`;
};

// AI Assistant endpoints
export const getAIAssistants = () => {
    return apiClient.get('/api/ai-assistants');
};

export const getAIAssistant = (id) => {
    return apiClient.get(`/api/ai-assistants/${id}`);
};

export const createAIAssistant = (assistantData) => {
    return apiClient.post('/api/ai-assistants', assistantData);
};

export const updateAIAssistant = (id, assistantData) => {
    return apiClient.put(`/api/ai-assistants/${id}`, assistantData);
};

export const reinitializeAIAssistant = (id) => {
  return apiClient.post(`/api/ai-assistants/${id}/reinitialize`);
};

export const getAssistantFiles = (assistantId) => {
    return apiClient.get(`/api/ai-assistants/${assistantId}/files`);
};

export const uploadAssistantFile = (formData, assistantId) => {
    return apiClient.post(`/api/ai-assistants/${assistantId}/files/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const deleteAssistantFile = (assistantId, fileId) => {
    return apiClient.delete(`/api/ai-assistants/${assistantId}/files/${fileId}`);
};

export const getDispensaryProducts = async (params) => {
    return await apiClient.get('/api/dispensary/products', { params });
};

export const getDispensaryLocations = async () => {
    return await apiClient.get('/api/dispensary/locations');
};

// Dispensary Manufacturing endpoints
export const startManufacturing = async (productId, manufacturingData) => {
  try {
    const response = await apiClient.post(
      `/api/dispensary-manufacturing/products/${productId}/manufacture`, 
      manufacturingData
    );
    return response.data;
  } catch (error) {
    console.error('\n apiClient.js:', error);
    throw error;
  }
};

export const completeVariantManufacturing = async (productId, manufacturingId, variantData) => {
  try {
    const response = await apiClient.post(
      `/api/dispensary-manufacturing/products/${productId}/manufacture/${manufacturingId}/complete-variant`,
      variantData
    );
    return response.data;
  } catch (error) {
    console.error('Error completing variant manufacturing:', error);
    throw error;
  }
};

export const completeAllManufacturing = async (productId, manufacturingId) => {
  try {
    const response = await apiClient.post(
      `/api/dispensary-manufacturing/products/${productId}/manufacture/${manufacturingId}/complete`
    );
    return response.data;
  } catch (error) {
    console.error('Error completing manufacturing:', error);
    throw error;
  }
};

export const getProductBOMs = async (productId) => {
  try {
    const response = await apiClient.get(`/api/dispensary/products/${productId}/used-in-boms`);
    return response.data;
  } catch (error) {
    console.error('Error fetching product BOMs:', error);
    throw error;
  }
};

export const getComponentStockLevels = async (locationName, components) => {
  try {
    console.log('getComponentStockLevels called with:', {
      locationName,
      components
    });
    const response = await apiClient.post('/api/dispensary/components/stock', {
      locationName,
      components
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching component stock levels:', error);
    throw error;
  }
};

// Add new function to check for in-progress manufacturing
export const getInProgressManufacturing = async (productId, locationName) => {
  try {
    const response = await apiClient.get(
      `/api/dispensary-manufacturing/products/${productId}/in-progress`,
      { params: { locationName } }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching in-progress manufacturing:', error);
    throw error;
  }
};

export const generateProductBOMs = async (productTypes) => {
  return await apiClient.post('/api/bom/generate', { productTypes });
};

export const getBatchCodesAIInsights = async () => {
  const response = await apiClient.get('/api/batch-codes/insights/ai');
  return response.data;
};

export const getDispensaryAIInsights = async (type) => {
    const response = await apiClient.get('/api/dispensary/insights/ai', { params: { type } });
    return response.data;
};

// Prescription API Functions
export const getPrescriptions = async (params) => {
  const response = await apiClient.get('/api/prescriptions', { params });
  return response.data;
};

export const getPrescription = async (id) => {
  const response = await apiClient.get(`/api/prescriptions/${id}`);
  return response.data;
};

export const createPrescription = async (prescriptionData) => {
  const response = await apiClient.post('/api/prescriptions', prescriptionData);
  return response.data;
};

export const updatePrescription = async (id, prescriptionData) => {
  const response = await apiClient.put(`/api/prescriptions/${id}`, prescriptionData);
  return response.data;
};

export const deletePrescription = async (id) => {
  const response = await apiClient.delete(`/api/prescriptions/${id}`);
  return response.data;
};

export const getCollectionLocations = async () => {
  const response = await apiClient.get('/api/prescriptions/collection-locations');
  return response.data;
};

export const getPrescriptionProducts = async (type) => {
  const response = await apiClient.get('/api/prescriptions/products', { params: { type } });
  return response.data;
};

// Add this with the other prescription functions
export const emailPrescription = async (prescriptionId) => {
  try {
    const response = await apiClient.post(`/api/prescriptions/${prescriptionId}/email`);
    return response.data;
  } catch (error) {
    console.error('Error sending prescription email:', error);
    throw error;
  }
};

// AI Insights endpoints
export const getAIInsights = () => {
  return apiClient.get('/api/ai-insights');
};

export const getAIInsightDetails = (insightId) => {
  return apiClient.get(`/api/ai-insights/${insightId}/data`);
};

export const interactWithInsight = (insightId, message) => {
  return apiClient.post(`/api/ai-insights/${insightId}/interact`, { message });
};

export const createAIInsight = (insightData) => {
  return apiClient.post('/api/ai-insights', insightData);
};

export const updateAIInsight = (insightId, insightData) => {
  return apiClient.put(`/api/ai-insights/${insightId}`, insightData);
};

// Add this new function with the other AI Insights endpoints
export const getAIInsightData = async (insightId) => {
  try {
    const response = await apiClient.get(`/api/ai-insights/${insightId}/data`);
    return response.data;
  } catch (error) {
    console.error('Error fetching insight data:', error);
    throw error;
  }
};

export const startInsightAnalysis = async (insightId) => {
  try {
    const response = await apiClient.post(`/api/ai-insights/${insightId}/analyze`);
    return response;
  } catch (error) {
    console.error('Error starting insight analysis:', error);
    throw error;
  }
};

export const downloadPractitionerCommissions = async (practitionerId, { startDate, endDate } = {}) => {
  return apiClient.get(`/api/practitioners/${practitionerId}/commissions/download`, {
    params: { startDate, endDate },
    responseType: 'arraybuffer'
  });
};

export const getBonusProducts = () => {
  return apiClient.get('/api/practitioners/bonus-products');
};

export const createBonusProduct = (productData) => {
  return apiClient.post('/api/practitioners/bonus-products', productData);
};

export const updateBonusProduct = (id, productData) => {
  return apiClient.put(`/api/practitioners/bonus-products/${id}`, productData);
};

export const deleteBonusProduct = (id) => {
  return apiClient.delete(`/api/practitioners/bonus-products/${id}`);
};

export const getDashboardData = () => {
  return apiClient.get('/api/practitioners/dashboard');
};

// Add these functions for handling conflicts
export const getOrderTagConflicts = () => {
  return apiClient.get('/api/practitioners/conflicts/tags');
};

export const markOrderConflictResolved = (orderNumber) => {
  // Remove any '#' from the order number if present
  const cleanOrderNumber = orderNumber.replace('#', '');
  console.log('Resolving conflict for order:', cleanOrderNumber);
  return apiClient.put(`/api/practitioners/conflicts/tags/${cleanOrderNumber}/resolve`);
};

export const verifyPin = async (prescriptionId, pin, role) => {
  const response = await apiClient.post('/api/prescriptions/verify-pin', { prescriptionId, pin, role });
  return response.data;
};

// Function to update PIN
export const updateUserPin = async (newPin, currentPin = null) => {
  try {
    const response = await apiClient.post('/api/auth/update-pin', {
      newPin,
      currentPin
    });
    return response.data;
  } catch (error) {
    console.error('Error updating PIN:', error);
    throw error;
  }
};

export const fulfillPrescription = async (formData) => {
  try {
    const response = await apiClient.post('/api/prescriptions/fulfill', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fulfilling prescription:', error);
    throw error;
  }
};

export const downloadPrescriptionLabel = async (prescriptionId) => {
  return await apiClient.get(`/api/prescriptions/${prescriptionId}/label`, {
    responseType: 'arraybuffer'
  });
};

// Store Agent endpoints
export const createStoreAgent = (agentData) => {
  return apiClient.post('/api/store-agents', agentData);
};

export const updateStoreAgent = (agentId, agentData) => {
  return apiClient.put(`/api/store-agents/${agentId}`, agentData);
};

export const getStoreAgents = () => {
  return apiClient.get('/api/store-agents');
};

export const getStoreAgentActivity = (agentId) => {
  return apiClient.get(`/api/store-agents/${agentId}/activity`);
};

export const getPendingApprovals = (agentId) => {
  return apiClient.get(`/api/store-agents/${agentId}/pending-approvals`);
};

export const approveAgentAction = (approvalId, approved) => {
  return apiClient.post(`/api/store-agents/approvals/${approvalId}`, { approved });
};

export const getAgentReports = (agentId) => {
  return apiClient.get(`/api/store-agents/${agentId}/reports`);
};

export const downloadAgentReport = async (reportId) => {
  const response = await apiClient.get(`/api/store-agents/reports/${reportId}/download`, {
    responseType: 'blob'
  });
  
  const blob = new Blob([response.data], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `agent-report-${reportId}.pdf`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export default apiClient;

export const getSkills = () => {
  return apiClient.get('/api/skills');
};

export const createSkill = (skillData) => {
  return apiClient.post('/api/skills', skillData);
};

export const updateSkill = (skillId, skillData) => {
  return apiClient.put(`/api/skills/${skillId}`, skillData);
};

export const deleteSkill = (skillId) => {
  return apiClient.delete(`/api/skills/${skillId}`);
};

export const getSkill = (skillId) => {
  return apiClient.get(`/api/skills/${skillId}`);
};

export const updateAgentSkill = (agentId, skillId, config) => {
  return apiClient.patch(`/api/agents/${agentId}/skills/${skillId}`, config);
};

export const addSkillToAgent = (agentId, skillId) => {
  return apiClient.post(`/api/agents/${agentId}/skills`, { skillId });
};

// Add this with the other prescription-related functions
export const emailPractitioner = async (prescriptionId, message) => {
  try {
    const response = await apiClient.post(`/api/prescriptions/${prescriptionId}/email-practitioner`, {
      message
    });
    return response.data;
  } catch (error) {
    console.error('Error sending practitioner email:', error);
    throw error;
  }
};

// Add this with other prescription-related functions
export const duplicatePrescription = async (prescriptionId) => {
  try {
    const response = await apiClient.post(`/api/prescriptions/${prescriptionId}/duplicate`);
    return response.data;
  } catch (error) {
    console.error('Error duplicating prescription:', error);
    throw error;
  }
};

// Add this new function with the other prescription-related functions
export const getPrescriptionHistory = async (prescriptionId) => {
  try {
    const response = await apiClient.get(`/api/prescriptions/history/${prescriptionId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching prescription history:', error);
    throw error;
  }
};

// Add new function to create a group of prescriptions
export const createPrescriptionGroup = async (prescriptions) => {
  try {
    const response = await apiClient.post('/api/prescriptions/group', { prescriptions });
    return response.data;
  } catch (error) {
    console.error('Error creating prescription group:', error);
    throw error;
  }
};

export const addAdditionalProducts = async (prescriptionId, additionalProducts) => {
  try {
    const response = await apiClient.post(`/api/prescriptions/${prescriptionId}/additional-products`, {
      additionalProducts
    });
    return response.data;
  } catch (error) {
    console.error('Error adding additional products:', error);
    throw error;
  }
};

// Practitioner referral endpoints
export const getReferredPractitioners = (practitionerId) => {
  return apiClient.get(`/api/practitioners/${practitionerId}/referred`);
};

export const getReferralStats = (practitionerId) => {
  return apiClient.get(`/api/practitioners/${practitionerId}/referral-stats`);
};

export const joinWithReferralCode = (practitionerData) => {
  return apiClient.post('/api/practitioners/join', practitionerData);
};

// Add these new functions (the others already exist)
export const searchPractitioners = async (query) => {
  try {
    const response = await apiClient.get('/api/practitioners/search', {
      params: { query }
    });
    return response.data;
  } catch (error) {
    console.error('Error searching practitioners:', error);
    throw error;
  }
};

export const addReferredPractitioner = (referringPractitionerId, practitionerId) => {
  return apiClient.post('/api/practitioners/add-referral', {
    referringPractitionerId,
    practitionerId
  });
};




